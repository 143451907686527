<template>
    <div v-if="filter.enable" class="mb-3 d-flex">
        <!-- <v-checkbox color="#00cccc" dense hide-details class="align-center pa-0 ma-0" :ripple="false"></v-checkbox> -->
        <v-btn
            v-if="active"
            small
            class="mr-2"
            color="primary"
            title="Hapus Filter"
            @click="clearSearch()">
              <v-icon
              small
              >mdi-close-thick</v-icon>
        </v-btn>
        <v-btn
            small
            class="mr-2"
            color="white"
            title="Print"
            @click="print=true">
              <v-icon
              small
              >mdi-printer-wireless</v-icon>
        </v-btn>
        <v-btn
            small
            class="mr-2"
            color="white"
            title="Export Data"
            @click="$store.state.settings.gizi ? dialog=true : exportData(null)">
              <v-icon
              small
              >mdi-table-arrow-down</v-icon>
        </v-btn>
        <v-autocomplete
          v-model="data"
          :items="ruangan"
          item-text="kamar"
          item-value="kamar"
          solo
          dense
          label="Ruang"
          multiple
          hide-details="auto"
          class="mr-2"
          @change="v=>searchFilter(v)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip x-small v-if="index < 1">
              <span>{{ item.kamar }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ data.length - 1 }})
            </span>
          </template>  
        </v-autocomplete>
        <v-dialog
          v-model="modal"
          :return-value.sync="date"
          width="290px"
        >
          <v-date-picker
            v-model="date"
            scrollable
            @input="selectDate"
          ></v-date-picker>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="500">
          <v-card class="white">
            <v-card-title> Pilih Data </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="white--text col"
                    @click.stop="exportData()"
                  >
                    Data Pasien
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="exportData('makan pagi')"
                  >
                    Pagi
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="exportData('makan siang')"
                  >
                    Siang
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="exportData('makan malam')"
                  >
                    Malam
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="print" scrollable max-width="500">
          <v-card class="white">
            <v-card-title> Pilih Data </v-card-title>
            <v-card-text>
              <v-radio-group
                v-model="snack_siang"
                row
                class="mt-0"
              >
                <template v-slot:label>
                  <div>Snack Siang:</div>
                </template>
                <v-radio
                  value="ganjil"
                >
                <template v-slot:label>
                  <div class="text-body-2">Ganjil</div>
                </template></v-radio>
                <v-radio
                  value="genap"
                >
                <template v-slot:label>
                  <div class="text-body-2">Genap</div>
                </template></v-radio>
              </v-radio-group>
              <v-radio-group
                v-model="snack_malam"
                row
                class="mt-0"
              >
                <template v-slot:label>
                  <div>Snack Malam:</div>
                </template>
                <v-radio
                  value="ganjil"
                >
                <template v-slot:label>
                  <div class="text-body-2">Biasa</div>
                </template></v-radio>
                <v-radio
                  value="genap"
                >
                <template v-slot:label>
                  <div class="text-body-2">Gorengan</div>
                </template></v-radio>
              </v-radio-group>
              <v-row dense>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'etiket_putih', waktu: 'pagi'})"
                  >
                    Makan Pagi
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'etiket_putih', waktu: 'siang', snack: snack_siang})"
                  >
                    Makan Siang
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'etiket_putih', waktu: 'malam', snack: snack_malam})"
                  >
                    Makan Malam
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'etiket_biru', waktu: 'siang', snack: snack_siang})"
                  >
                    Snack Siang
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'etiket_biru', waktu: 'sore', snack: snack_malam})"
                  >
                    Snack Sore
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'etiket_biru', waktu: 'malam'})"
                  >
                    Snack DM Malam
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'racikan_biru', waktu: 'makan'})"
                  >
                    Diit Cair
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    class="col"
                    @click.stop="printData({type:'racikan', waktu: 'parcel'})"
                  >
                    Parcel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InputField from "@/components/InputField";
import moment from 'moment'

export default {
  name: "FilterButton",
  components: {
    InputField
  },
  props: {
    active: {
      default: false,
    },
  },
  data() {
    return {
      click: null,
      data: [],
      tanggal: 1,
      modal: false,
      dialog: false,
      show: false,
      date: moment().format('YYYY-MM-DD'),
      filterItems :
      [
        {title: 'Hari ini', value: moment(new Date().toISOString().substr(0, 10)).format('x')},
        {title: 'Semua', value: 0},
        {title: 'Pilih tanggal', value: 0}
      ],
      print: false,
      ruangan: [],
      snack_siang: 'ganjil',
      snack_malam: 'ganjil'
    }
  },
  computed: {
    items() {
      return this.$store.state.settings[this.filter.trigger] || [];
    },
    filter() {
      return this.$store.state.settings.filter;
    }
  },
  created() {
    this.getRuangan();
  },
  methods: {    
    getRuangan() {
      if (this.$store.state.settings.gizi && this.filter.enable) {
        this.axios
          .get(`${window.rawUrl}/assets/js/ruangan.js`)
          .then((r) => {
            this.ruangan = r.data;
            // console.log(this.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    },
    selectRuangan(v) {
      if (v) {
        this.data.ruangan = v.kamar;
      }
    },
    selectDate(v) {
      this.modal = false;
      this.show = false;
      this.filterItems[2].value = moment(v).format('x');
      this.tanggal = 2;
      this.dateFilter(moment(v).format('x'));
    },
    searchFilter(item) {
      let tanggal = this.filterItems[this.tanggal]
      let data = {
        trigger: this.filter.trigger,
        filter: this.data,
        tanggal: tanggal.value
      }
      console.log(data);
      this.$emit('doFilter', data);
    },
    dateFilter(item) {
      let data = {
        trigger: this.filter.trigger,
        filter: this.data,
        tanggal: item
      }
      console.log(data);
      this.$emit('doFilter', data);
    },
    clearSearch() {
      this.data = []
      this.tanggal = 1
      this.$emit('clearFilter');
    },
    exportData(v) {
      this.dialog = false;
      this.$emit('exportData', v);
    },
    printData(v) {
      this.print = false;
      this.$emit('printData', v);
    }
  },
  watch: {
    active(v) {
      if(!v) {
        this.data = []
        this.tanggal = 1
      }
    }
  }
};
</script>
